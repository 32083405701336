import React from 'react'
import Helmet from 'react-helmet'
import LangSelector from '../components/lang-selector'
import Layout from '../components/layout'
import Header from '../components/header'
import Section from '../components/section'
import data from './data'

const IndexPt = () => (
  <Layout>
    <Helmet>
      <meta name='description' content='Programador de aplicativos iOS. Vive no interior de Minas Gerais e trabalha remotamente desde 2015.' />
      <meta name='keywords' content='apps, programação, developer, android, ios, app, divinópolis, minas gerais, react, native, ruby, rails, python, swift, java, kotlin, obj-c, flutter, gdg' />
      <html lang='pt-br' />
    </Helmet>
    <LangSelector />
    <Header email={data.email} introArray={data.intro} />
    <Section title='redes sociais' links={data.links} />
    <Section title='trabalho com' tags={data.tools} />
    <Section title='palestras' list={data.talks} />
    <Section title='prêmios' list={data.awards} />
  </Layout>
)

export default IndexPt
