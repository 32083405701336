import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  margin-bottom: 1rem;
  padding: 0.5rem;
`

const Title = styled.h3`
  font-weight: bold;
  margin-bottom: 0.5rem;
`

const Tags = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  span {
    margin-bottom: .5rem;
  }

  span:not(:last-child) {
    margin-right: .5rem;
  }
`

const Section = ({title, links, tags, list}) => (
  <Container>
    <Title>{title}</Title>

    {links && links.map((item, index) => 
      <span key={index}>
        {index > 0 && <span> • </span>}
        <a href={item.link}>{item.title}</a>
      </span>
    )}

    {tags && <Tags>{tags.map((item, index) => <span key={index} className='tag'>{item}</span>)}</Tags>}

    {list && list.map((item, index) => <p key={index}>• {item}</p>)}

  </Container>
)

Section.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array,
  tags: PropTypes.array,
  list: PropTypes.array,
}

export default Section
