import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import avatar from './../images/avatar.png'
import avatar2x from './../images/avatar@2x.png'
import avatar3x from './../images/avatar@3x.png'

const Container = styled.div`
  margin-bottom: 1rem;
  padding: 0.5rem;
`

const AvatarContainer = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
`

const Email = styled.h1`
  font-size: 1.6rem;
  text-align: center;
`

const Header = ({ email, introArray }) => (
  <>
    <Container>
      <AvatarContainer>
        <img src={avatar} srcSet={`${avatar2x} 2x, ${avatar3x} 3x`} alt='Avatar' />
      </AvatarContainer>
      { introArray.map((item, index) => <p key={index}>{item}</p>) }
      <hr />
      <Email>{ email }</Email>
    </Container>
    <hr />
  </>
)

Header.propTypes = {
  email: PropTypes.string.isRequired,
  introArray: PropTypes.array.isRequired,
}

export default Header
